import { useState } from 'react';
import HomeBannerImage from '../../assets/banners/banner1.png';

export default function HomeBanner() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className="video-banner-container position-relative pt-5">
      {/* Thumbnail Image */}
      
      {!videoLoaded && (
        <img
          src={HomeBannerImage}
          alt="Banner Thumbnail"
          className="video-thumbnail w-100 h-100 position-absolute top-20 start-0"
        />
      )}

      {/* YouTube Video */}
      <iframe
        src="https://www.youtube.com/embed/FjGQF3B6GE4?autoplay=1&loop=1&mute=1&controls=0&start=5&playlist=FjGQF3B6GE4"
        className="video-banner w-100 h-100 position-absolute top-0 start-0"
        allow="autoplay; encrypted-media"
        title="Video Banner"
        frameBorder="0"
        onLoad={() => setVideoLoaded(true)} // Hides the thumbnail when video loads
      ></iframe>

      {/* Overlay Text */}
      <div data-aos="zoom-in" className="video-banner-overlay position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white">
        <h1 className="fw-bold display-3" data-aos="zoom-in">WELCOME TO RANAV GROUP</h1>
        <h4 className="mt-3">Dreams Refined into Reality</h4>
      </div>
    </div>
  );
}
