import Slider from "react-slick";
import TownHouse from '../../assets/icons/townHouse.png';
import ModernVilla from '../../assets/icons/modernVilla.png';
import Appartment from '../../assets/icons/appartment.png';
import Office from '../../assets/icons/office.png';
import SingleFamily from '../../assets/icons/singleFamily.png';
import ShopHouse from '../../assets/icons/shopHouse.png';



export default function FeaturedCategories(){
const settings = {
    dots: false,
    speed: 1500,
    slidesToShow: 6,  // Default on large screens
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
        {
            breakpoint: 1024, // Tablet view
            settings: {
                slidesToShow: 4,  
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 768, // Mobile view (can change based on preference)
            settings: {
                slidesToShow: 3,  
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 576, // Extra small screens (like very small mobiles)
            settings: {
                slidesToShow: 2, 
                slidesToScroll: 1,
            }
        }
    ]
};
    return(
        <>
        <div className="bg-gray text-center py-5 mb-5"> 
            <h1 data-aos="fade-up" className="yello-text">
                 Featured Categories
            </h1>
            <p data-aos="fade-up" className="white-text text17 pt-3">Explore Our Handpicked Selection of Featured Collections.</p>
            <div data-aos="zoom-in" className="categories-section container pt-5">
                <Slider {...settings}>
                    <div className="category-card card pt-5 pb-4">
                        <img src={TownHouse} className="pt-2 pb-4" />
                        <h5>Town House</h5>
                        <p>2 Properties</p>
                    </div>
                    <div className="category-card card pt-5 pb-4">
                        <img src={ModernVilla} className="pt-2 pb-4" />
                        <h5>Modern Villa</h5>
                        <p>10 Properties</p>
                    </div>
                    <div className="category-card card pt-5 pb-4">
                        <img src={Appartment} className="pt-2 pb-4" />
                        <h5>Apartment</h5>
                        <p>3 Properties</p>
                    </div>
                    <div className="category-card card pt-5 pb-4">
                        <img src={Office} className="pt-2 pb-4" />
                        <h5>Office</h5>
                        <p>3 Properties</p>
                    </div>
                    <div className="category-card card pt-5 pb-4">
                        <img src={SingleFamily} className="pt-2 pb-4" />
                        <h5>Single Family</h5>
                        <p>5 Properties</p>
                    </div>
                    <div className="category-card card pt-5 pb-4">
                        <img src={ShopHouse} className="pt-2 pb-4" />
                        <h5>Shop House</h5>
                        <p>2 Properties</p>
                    </div>
                    <div className="category-card card pt-5 pb-4">
                        <img src={ShopHouse} className="pt-2 pb-4" />
                        <h5>New Category</h5>
                        <p>1 Property</p>
                    </div>
                </Slider>
            </div>
            <div data-aos="fade-right" className="flex float-right container text-end mt-5">
                <button className="yello-text cat-btn">View all Categories</button>
            </div>
        </div>
        </>
    )
}