import Promises from '../../assets/banners/promise.jpg';

export default function Promise() {
    return (
        <section className="promise-section container-fluid text-white py-5">
            <div className="container row align-items-center">
                {/* Text Content */}
                <div className='col-md-1 col-sm-12'></div>
                <div data-aos="fade-right" className="col-md-5 col-sm-12">
                    <h6 className="small-heading text-uppercase">A Gateway to</h6>
                    <h6 className="small-heading text-uppercase">Your Dream Home</h6>
                    <h1 className="yellow-text pb-3">The Ranav Group Promise</h1>
                    <p>
                        Over a decade of dedication has shaped the reputation that Ranav Group proudly holds in Southern India today. 
                        With over 8 million square feet of meticulously crafted spaces across both residential and commercial segments, 
                        we have upheld the highest standards of quality and attention to detail in every project.
                    </p>
                    <p>
                        It’s no surprise that our customers feel at home even before moving in—because we don’t just build structures, 
                        we bring their dreams to life. This is the unwavering promise of Ranav Group.
                    </p>
                </div>
                <div className='col-md-1'></div>
                <div data-aos="zoom-out" className="col-md-5 col-12">
                <img src={Promises} alt="Ranav Group Promise" className="img-fluid rounded" />
                </div>
            </div>
        </section>
    );
}
