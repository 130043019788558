import React, { useState } from "react";
import logo from "../../assets/contact.png";

export default function ContactForm() {
  const [data, setData] = useState({
    username: "",
    phoneno: "",
    emailid: "",
    textar: "", // Update this to match your textarea name
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // State to track submission status

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value, // Dynamically set form data
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://ranavgroup.com/contact_form.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data),
      });

      const result = await response.json();
      console.log(result);
      if (result.status === "success") {
        setIsSubmitted(true); // Update submission status
      } else {
        alert("Failed to submit data: " + result.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <>
      {isSubmitted ? ( // Conditional rendering based on submission status
        <div data-aos="zoom-in" className="thank-you-message">
          <h2>Thank you for contacting us!</h2>
          <p>We will get back to you soon!....</p>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6 form-container">
            <h1 className="contact-txt">Contact Us</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  className="fpe-inputs"
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Full Name*"
                  value={data.username} // Bind input to state
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="fpe-inputs"
                  type="number"
                  name="phoneno"
                  id="phoneno"
                  placeholder="Phoneno*"
                  value={data.phoneno}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="fpe-inputs"
                  type="email"
                  name="emailid"
                  id="emailid"
                  placeholder="Email ID*"
                  value={data.emailid}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="txt-ar"
                  name="textar" // Update to match the state property
                  placeholder="Message*"
                  value={data.textar} // Bind textarea to state
                  rows="4"
                  cols="50"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="sub-btn">
                Submit
              </button>
            </form>
          </div>
          <div className="col-md-6">
            <img src={logo} alt="" className="contact-img" />
          </div>
        </div>
      )}
    </>
  );
}
